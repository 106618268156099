<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-sm-6 p-md-0">
        <div class="welcome-text">
          <h4>Bank Statement</h4>
        </div>
      </div>
      <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }"
              >Dashboard</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Financials' }">Financial</router-link>
          </li>
          <li class="breadcrumb-item active">Bank Statement</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="" class="tab-pane fade active show col-md-12">
        <div class="card top-red-border">
          <div class="card-header" style="display: flow-root">
            <!-- FeedBack -->
            <feedback-messages :msgs="msgs" :errors="errors" />
            <!-- /FeedBack -->
            <div class="basic-form">
              <form
                id="financial_bank_statement"
                @submit.prevent="submitFormData()"
              >
                <div class="form-row align-items-end">
                  <div class="form-group col-md-3">
                    <label>Account <span style="color: red">*</span></label>
                    <select class="form-control" name="account_id" required>
                      <option value="" disabled selected>
                        Nothing Selected
                      </option>
                      <option
                        v-for="account in lists.accounts"
                        :key="account.id"
                        :value="account.id"
                      >
                        {{ account.name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Date From <span style="color: red">*</span></label>
                    <input
                      required
                      type="date"
                      class="form-control"
                      name="from"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label>Date To <span style="color: red">*</span></label>
                    <input
                      required
                      type="date"
                      class="form-control"
                      name="to"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <button
                      :disabled="disableBtn"
                      name="submit"
                      type="submit"
                      class="btn btn-primary"
                      value="Submit"
                      onclick="this.blur();"
                    >
                      Display
                    </button>
                    <button
                      :disabled="disableBtn"
                      style="margin-left: 5px"
                      name="submit"
                      type="submit"
                      class="btn btn-primary"
                      value="Submit"
                      onclick="this.blur();"
                      @click.prevent="downloadFile()"
                    >
                      Download
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="card-body">
            <p v-if="balance != 0"><strong>Balance: </strong>{{ balance }}</p>
            <div class="table-responsive" v-if="statements.length != 0">
              <table
                class="
                  table table-bordered table-striped
                  verticle-middle
                  table-responsive-sm
                "
              >
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Debit</th>
                    <th scope="col">Credit</th>
                    <th scope="col">Balance</th>
                    <th scope="col">Date</th>
                  </tr>
                </thead>
                <tbody v-if="statements.length">
                  <tr v-for="statement in statements" :key="statement.id">
                    <td>{{ statement.name }}</td>
                    <td>{{ statement.debit }}</td>
                    <td>{{ statement.credit }}</td>
                    <td>{{ statement.balance }}</td>
                    <td>{{ statement.date }}</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td style="text-align: center" colspan="5">
                      No Data Available.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FeedbackMessages from "@/components/FeedbackMessages.vue";
import useFinancialReports from "@/composables/financial_reports";
import { inject, onBeforeMount, ref } from "vue";
import { useRouter } from "vue-router";
export default {
  components: {
    FeedbackMessages,
  },
  setup() {
    const swal = inject("$swal");
    const hasPermission = inject("hasPermission");
    const disableBtn = ref(false);
    const router = useRouter();

    onBeforeMount(() => {
      //first: check the user Permission
      if (!hasPermission("bank_statement_financial_reports"))
        router.push({
          name: "401",
        });
      else getLists(['accounts']);
    });

    const {
      getBankStatement,
      getLists,
      downloadBankStatement,
      statements,
      balance,
      lists,
      msgs,
      errors,
    } = useFinancialReports();

    const submitFormData = async () => {
      var formDataObj = new FormData();
      const form = document.querySelector("#financial_bank_statement");
      Array.from(form.elements).forEach((input) => {
        formDataObj.append(input.name, input.value);
      });

      swal.fire({
        title: "Please wait while submit your request",
        allowOutsideClick: false,
        didOpen: () => {
          swal.showLoading();
        },
      });

      errors.value = [];
      disableBtn.value = true;

      await getBankStatement(formDataObj).then(() => {
        swal.close();
        window.scrollTo(0, 0);
        disableBtn.value = false;
      });
    };

    const downloadFile = async () => {
      var formDataObj = new FormData();
      const form = document.querySelector("#financial_bank_statement");
      Array.from(form.elements).forEach((input) => {
        formDataObj.append(input.name, input.value);
      });

      swal.fire({
        title: "Please wait while submit your request",
        allowOutsideClick: false,
        didOpen: () => {
          swal.showLoading();
        },
      });

      errors.value = [];
      disableBtn.value = true;

      await downloadBankStatement(formDataObj).then(() => {
        swal.close();
        window.scrollTo(0, 0);
        disableBtn.value = false;
      });
    };

    return {
      submitFormData,
      statements,
      downloadFile,
      balance,
      lists,
      msgs,
      errors,
    };
  },
};
</script>