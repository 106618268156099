import {
    ref,
    reactive
} from 'vue';
import axios from '@/axios';
import store from '@/store';

export default function useFinancialReports() {

    const errors = ref([]); //ref: to make it reactive
    const msgs = ref([]);
    const statements = ref([]);
    const installments = ref([]);
    const balance = ref(0);
    const lists = reactive({});

    const getLists = async (items) => {
        try {
            let data = { 'items': items };
            let response = await axios.post('/api/financial_reports/lists', data);
            lists.accounts = response.data.data.accounts;
            lists.years = response.data.data.years;
        } catch (e) {
            await store.dispatch("handleException", e);
        }
    }

    const getBankStatement = async (data = {}) => {
        try {
            data.append("action", 'dispaly');
            let response = await axios.post('/api/financial_reports/bank_statement', data);
            statements.value = response.data.data.statements;
            balance.value = response.data.data.balance;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }

    }

    const downloadBankStatement = async (data) => {

        data.append("action", 'download');
        axios({
            url: '/api/financial_reports/bank_statement',
            method: 'POST',
            responseType: 'blob',
            data: data
        }).then((response) => {

            //let fileExtension = response.data.type.split('/').pop();

            var d = new Date();
            var date = d.toUTCString();

            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'Bank Statment ' + date + '.xlsx');
            document.body.appendChild(fileLink);

            fileLink.click();
        });

    }

    //
    const getInstallmentsReport = async (data = {}) => {
        try {
            data.append("action", 'dispaly');
            let response = await axios.post('/api/financial_reports/installments_report', data);
            installments.value = response.data.data.installments;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }
    }

    const downloadInstallmentsReport = async (data = {}) => {
        data.append("action", 'download');
        axios({
            url: '/api/financial_reports/installments_report',
            method: 'POST',
            responseType: 'blob',
            data: data
        }).then((response) => {

            //let fileExtension = response.data.type.split('/').pop();

            var d = new Date();
            var date = d.toUTCString();

            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'installments ' + date + '.xlsx');
            document.body.appendChild(fileLink);

            fileLink.click();
        });
    }



    return {
        getBankStatement,
        getInstallmentsReport,
        downloadInstallmentsReport,
        getLists,
        downloadBankStatement,
        installments,
        statements,
        balance,
        lists,
        msgs,
        errors,
    }
}